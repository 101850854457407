export default class ValidationException extends Error {
    constructor({ type = null, message = null, htmlElement = undefined }) {
        super(message);

        this.type = type;
        this.name = "ValidationException";
        this.htmlElement = htmlElement;

        if(htmlElement) {
            /**
             * Scroll element into view
             */
            htmlElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }
}