import Cookies from 'js-cookie';

export default class Menu {
    navCookieName = 'isNavToggled';
    isNavToggled = false;
    navContainer = null;
    toggleButton = null;
    pageContainer = null;
    budgetTableContainer = null;
    topBar = null;
    navLinks = null;

    constructor() {
        this.menuInit();
    }

    menuInit = () => {
        try {
            this.navContainer = document.querySelector('.nav');
            this.toggleButton = this.navContainer && this.navContainer.querySelector('.navbar__footer .toggle');
            this.pageContainer = document.querySelector('.page-container');
            this.budgetTableContainer = document.querySelector('.table-container');
            this.topBar = document.querySelector('.topbar');
            this.navLinks = document.querySelectorAll('.collapsed');

            if (this.toggleButton) {
                this.menuToggleInit();
                this.bindToggleButton();
                this.bindItemButtons();
            }
        } catch (error) {
            this.notification.showDefaultError(error);
        }
    }

    menuToggleInit = () => {
        const { navCookieName } = this;

        this.isNavToggled = Cookies.get(navCookieName) === "true" ? true : false;
    }

    bindToggleButton() {
        const { navCookieName } = this;

        this.toggleButton.addEventListener('click', () => {
            Cookies.remove(navCookieName, { path: '/' });
            this.isNavToggled = !this.isNavToggled;
            Cookies.set(navCookieName, this.isNavToggled, { path: '/' });
            this.navContainer.classList.toggle('toggled');
            this.pageContainer.classList.toggle('page-container--nav-narrow');
            this.topBar.classList.toggle('nav-toggled');
        });

        document.addEventListener('click', (event) => {
            this.navLinks.forEach(link => {
                const item = link.parentElement;
                const innerContainer = item.querySelector('.collapse');

                if (
                    event.target.parentElement !== link
                    && event.target !== link
                ) {
                    item.classList.remove('collapsed');
                    innerContainer.classList.remove('show');
                }
            });
        });
    }

    bindItemButtons() {
        this.navLinks.forEach(link => {
            const item = link.parentElement;
            const innerContainer = item.querySelector('.collapse');

            link.addEventListener('click', () => {
                this.turnOffCollapseDivs(link);
                item.classList.toggle('collapsed');
                innerContainer.classList.toggle('show');
            });
        });
    }

    turnOffCollapseDivs(clickedItem) {
        this.navLinks.forEach(link => {
            if (link !== clickedItem) {
                const item = link.parentElement;
                const innerContainer = item.querySelector('.collapse');

                item.classList.remove('collapsed');
                innerContainer.classList.remove('show');
            }
        });
    }

}