require('bootstrap-notify');

export default class Notification {

    icons = {
        danger: 'fas fa-exclamation-circle',
        warning: 'fas fa-exclamation-circle',
        success: 'fas fa-check-circle',
    }

    types = {
        danger: 'danger',
        warning: 'warning',
        success: 'success',
    }

    constructor(locale) {
        this.locale = locale;

        this.setDefaults();
        this.showSessionNotify();
    }

    setDefaults = () => {
        $.notifyDefaults({
            z_index: 1600,
            allow_dismiss: true,
            animate: {
                enter: 'animated slideInRight',
                exit: 'animated slideOutRight'
            },
        });
    }

    showSessionNotify = () => {
        if (notifySession) {
            this.show(this.types.success, notifySession);
        }
    }

    getSettings = type => {
        switch (type) {
            case this.types.danger:
                return {
                    type: this.types.danger,
                    icon: this.icons.danger
                }
            case this.types.success:
                return {
                    type: this.types.success,
                    icon: this.icons.success
                }
            case this.types.warning:
                return {
                    type: this.types.warning,
                    icon: this.icons.warning
                }
            default:
                throw new Error(`Niewłaściwy typ powiadomienia: ${type}`);
        }
    }

    show = (type, message) => {
        const settings = this.getSettings(type);

        $.notify({
            icon: settings.icon,
            message: message
        }, {
            type: settings.type
        });
    }

    showDefaultError = (errorMessage = null) => {
        if (errorMessage !== null && process.env.ENVIRONMENT === "development") {
            console.error(errorMessage);
        }

        this.show(this.types.danger, this.locale.errorApplicationMessage);
    }
}