import Global from './global/global.js';

import Form from './global/form.js';
import ValidationException from './global/exceptions/validationException.js';

import Cleave from 'cleave.js';
import "cleave.js/dist/addons/cleave-phone.pl";

import Questionnaire from './global/questionnaire.js';

class DonorApplicationManagementMicrobiomeDonorFormView extends Global {

    constructor() {
        super();

        this.form = new Form(document.getElementById('donorApplicationMicrobiomeForm'));
        this.bindFormButton();
        this.bindFormInputMasks();

        this.questionnaire = new Questionnaire(this.locale, this.notification, this.form);
    }

    bindFormButton = () => {
        const { formElement } = this.form;

        formElement.addEventListener('submit', async e => {
            try {
                e.preventDefault();

                // browser already validated inputs according to HTML5 attributes
                formElement.classList.add("after-validation");

                this.questionnaire.validation();

                const formData = new FormData();
                this.modifyFormData(formData);

                const response = await fetch(formElement.action, {
                    method: "POST",
                    body: formData,
                });

                const notification = await response.json();
                this.formatFormResponse(notification);
            } catch (error) {
                if(error instanceof ValidationException) this.notification.show(error.type, error.message);
                else this.notification.showDefaultError(error);
            }
        });
    }

    bindFormInputMasks = () => {
        const { formElement } = this.form;

        /**
         * phoneNumber
         * +48 xxx xxx xxx
         */
        let inputPhoneNumber = formElement.querySelector(`input[name='phoneNumber']`);
        new Cleave(inputPhoneNumber, {
            phone: true,
            phoneRegionCode: 'PL',
            prefix: '+48',
        });

        /**
         * phoneNumber
         * +48 xxx xxx xxx
         */
        let inputPostalCode = formElement.querySelector(`input[name='postalCode']`);
        new Cleave(inputPostalCode, {
            delimiter: '-',
            blocks: [2,3]
        });
    }

    modifyFormData = formData => {
        const { formElement } = this.form;
        const { agreements, questions } = this.questionnaire;

        const address = {
            street: formElement.querySelector(`input[name='street']`).value,
            postalCode: formElement.querySelector(`input[name='postalCode']`).value,
            city: formElement.querySelector(`input[name='city']`).value,
            voivodeship: formElement.querySelector(`select[name='voivodeship']`).value,
            country: (formElement.querySelector(`select[name='country']`).value ? formElement.querySelector(`select[name='country']`).value : 'Polska'),
        }
        const donor = {
            email: formElement.querySelector(`input[name='email']`).value,
            firstName: formElement.querySelector(`input[name='firstName']`).value,
            lastName: formElement.querySelector(`input[name='lastName']`).value,
            pesel: formElement.querySelector(`input[name='pesel']`).value,
            birthYear: formElement.querySelector(`select[name='birthYear']`).value,
            gender: formElement.querySelector(`select[name='gender']`).value,
            height: formElement.querySelector(`input[name='height']`).value,
            weight: formElement.querySelector(`input[name='weight']`).value,
            phoneNumber: formElement.querySelector(`input[name='phoneNumber']`).value,
            address: JSON.stringify(address),
        }
        formData.append('donor', JSON.stringify(donor));

        //agreements
        const signedAgreements = [];
        agreements.forEach(agreement => {
            if (agreement.isSigned()) {
                signedAgreements.push({
                    agreementId: agreement.id,
                });
            }
        });
        formData.append('agreements', JSON.stringify(signedAgreements));

        //answers
        const answers = [];
        questions.forEach(question => {
            if(question.meetsDisplayConditions()) {
                let answer = question.getValue();

                answers.push({
                    questionId: question.id,
                    answers: JSON.stringify(question.getAnswers()),
                    comment: question.getComment(),
                });
            }
        });
        formData.append('answers', JSON.stringify(answers));
    }
}

new DonorApplicationManagementMicrobiomeDonorFormView();