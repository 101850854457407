
import ValidationException from './exceptions/validationException.js';

import Question from './questionnaire/question.js';
import Agreement from './questionnaire/agreement.js';

export default class Questionnaire {
    constructor(locale, notification, form) {
        this.locale = locale;
        this.form = form;
        this.notification = notification;

        this.questions = [];
        this.setQuestions();

        this.agreements = [];
        this.setAgreements();

        this.initInputLogic();
    }

    setQuestions = () => {
        const { formElement } = this.form;
        const questions = formElement.querySelectorAll('.question');

        let that = this;

        questions.forEach(questionElement => {
            that.questions.push(new Question(questionElement));
        });
    }

    setAgreements = () => {
        const { formElement } = this.form;
        const agreements = formElement.querySelectorAll('.agreement');

        let that = this;

        agreements.forEach(agreementHtmlElement => {
            that.agreements.push(new Agreement(agreementHtmlElement));
        });
    }

    validation = () => {
        try {
            this.donorInformationValidation();
            this.agreementsValidation();
            this.answersValidation();
            this.answerCommentsValidation();
        } catch (error) {
            throw error;
        }
    }

    initInputLogic = () => {
        this.bindPeselUpdateGenderAndAge();

        this.bindPeselValidation();
        this.bindBirthYearValidation();
        this.bindGenderValidation();
        this.bindEmailValidation();
    }

    bindPeselUpdateGenderAndAge = () => {
        const that = this;
        const { formElement } = this.form;
        let element = formElement.querySelector(`input[name='pesel']`);

        element.addEventListener('keyup', function() {
            let inputPesel = document.querySelector(`input[name='pesel']`);
            let inputBirthYear = document.querySelector(`select[name='birthYear']`);
            let inputGender = document.querySelector(`select[name='gender']`);

            let isValid = (inputPesel.value.length === 11);

            if(isValid) {
                inputBirthYear.value = that.getPeselBirthYear(this.value);
                inputBirthYear.dispatchEvent(new Event('change'));

                inputGender.value = that.getPeselGender(this.value);
                inputGender.dispatchEvent(new Event('change'));
            }
        });
    }

    getPeselBirthYear = (pesel) => {
        let peselArray = pesel.split('');
        let year = 1900 + (parseInt(peselArray[0]) * 10) + parseInt(peselArray[1]);
        
        if (parseInt(peselArray[2]) >= 2 && parseInt(peselArray[2]) < 8) year += Math.floor(parseInt(peselArray[2]) / 2) * 100;
        if (parseInt(peselArray[2]) >= 8) year -= 100;
        return year;
    }

    getPeselGender = (pesel) => {
        let peselArray = pesel.split('');
        return ((peselArray[9]%2==1) ? "MALE" : "FEMALE");
    }

    bindPeselValidation = () => {
        const { formElement } = this.form;
        let element = formElement.querySelector(`input[name='pesel']`);

        element.addEventListener('keyup', function() {
            let inputPesel = document.querySelector(`input[name='pesel']`);
            let inputBirthYear = document.querySelector(`select[name='birthYear']`);
            let inputGender = document.querySelector(`select[name='gender']`);

            let isValid = (inputPesel.value.length === 11);
            let meetsOtherRequirement = (inputBirthYear.value && inputGender.value);

            if(isValid || meetsOtherRequirement) {
                inputPesel.setCustomValidity(``);
                inputBirthYear.setCustomValidity(``);
                inputGender.setCustomValidity(``);
            } else {
                inputPesel.setCustomValidity(` `);
            }
        });
    }

    bindBirthYearValidation = () => {
        const { formElement } = this.form;
        let element = formElement.querySelector(`select[name='birthYear']`);

        element.addEventListener('change', function() {
            let inputPesel = document.querySelector(`input[name='pesel']`);
            let inputBirthYear = document.querySelector(`select[name='birthYear']`);
            let inputGender = document.querySelector(`select[name='gender']`);

            let meetsOtherRequirement = (inputPesel.value.length === 11);

            if( (inputBirthYear.value && inputGender.value) || meetsOtherRequirement) {
                inputPesel.setCustomValidity(``);
                inputBirthYear.setCustomValidity(``);
                inputGender.setCustomValidity(``);
            } else if(inputBirthYear.value) {
                inputBirthYear.setCustomValidity(``);
                if( !(inputGender.value) ) inputGender.setCustomValidity(` `);
            } else {
                inputBirthYear.setCustomValidity(` `);
                inputGender.setCustomValidity(` `);
            }
        });
    }

    bindGenderValidation = () => {
        const { formElement } = this.form;
        let element = formElement.querySelector(`select[name='gender']`);

        element.addEventListener('change', function() {
            let inputPesel = document.querySelector(`input[name='pesel']`);
            let inputBirthYear = document.querySelector(`select[name='birthYear']`);
            let inputGender = document.querySelector(`select[name='gender']`);

            let meetsOtherRequirement = (inputPesel.value.length === 11);

            if( (inputBirthYear.value && inputGender.value) || meetsOtherRequirement) {
                inputPesel.setCustomValidity(``);
                inputBirthYear.setCustomValidity(``);
                inputGender.setCustomValidity(``);
            } else if(inputGender.value) {
                inputGender.setCustomValidity(``);
                if( !(inputBirthYear.value) ) inputBirthYear.setCustomValidity(` `);
            } else {
                inputGender.setCustomValidity(` `);
                inputBirthYear.setCustomValidity(` `);
            }
        });
    }

    bindEmailValidation = () => {
        const { formElement } = this.form;
        let element = formElement.querySelector(`input[name='emailRepeat']`);

        element.addEventListener('keyup', function() {
            let inputEmail = document.querySelector(`input[name='email']`);
            let inputRepeatEmail = document.querySelector(`input[name='emailRepeat']`);

            let assertEqual = (inputEmail.value === inputRepeatEmail.value);

            if( (inputEmail.value && inputRepeatEmail.value) && assertEqual) {
                inputRepeatEmail.setCustomValidity(``);
            } else {
                inputRepeatEmail.setCustomValidity(` `);
            }
        });
    }

    inputValidation = (htmlElement, message, minLength = 1) => {
        const { types } = this.notification;

        if(htmlElement.value && htmlElement.value.length >= minLength) {
        } else {
            throw new ValidationException({type: types.warning, message: message, htmlElement: htmlElement});
        }
    }

    donorInformationValidation = () => {
        const { locale } = this;
        const { formElement } = this.form;
        const { types } = this.notification;

        try {
            let input, select;

            /**
             * Personal: firstName, lastName
             */
            input = formElement.querySelector(`input[name='firstName']`);
            if(input) {
                this.inputValidation(input, locale.validationFirstNameRequired);
            }

            input = formElement.querySelector(`input[name='lastName']`);
            if(input) {
                this.inputValidation(input, locale.validationLastNameRequired);
            }

            /**
             * Contact: phoneNumber, email, pesel/birthYear/gender, city
             */
            input = formElement.querySelector(`input[name='phoneNumber']`);
            if(input) {
                this.inputValidation(input, locale.validationCityRequired);
            }

            let inputEmail = formElement.querySelector(`input[name='email']`);
            let inputEmailRepeat = formElement.querySelector(`input[name='emailRepeat']`);
            if(inputEmail) {
                this.inputValidation(inputEmail, locale.validationEmailRequired);
            }
            if(inputEmailRepeat) {
                this.inputValidation(inputEmail, locale.validationEmailRequired);
            }
            if(inputEmail.value != inputEmailRepeat.value) {
                this.inputValidation(inputEmailRepeat, locale.validationEmailRepeatIncorrect);
            }

            input = formElement.querySelector(`input[name='email']`);
            if(input) {
                this.inputValidation(input, locale.validationCityRequired);
            }

            input = formElement.querySelector(`input[name='city']`);
            if(input) {
                this.inputValidation(input, locale.validationCityRequired);
            }

            /**
             * pesel or birthYear and gender
             */
            let inputPesel = formElement.querySelector(`input[name='pesel']`);
            let inputBirthYear = formElement.querySelector(`select[name='birthYear']`);
            let inputGender = formElement.querySelector(`select[name='gender']`);

            if(inputPesel.value || (inputBirthYear.value && inputGender.value)) {
                // OK
            } else {
                this.inputValidation(inputGender, locale.validationPeselBirthYearGenderRequired);
            }

            /**
             * Details: weight, height
             */
            input = formElement.querySelector(`input[name='weight']`);
            if(input) {
                this.inputValidation(input, locale.validationWeightRequired);
            }

            input = formElement.querySelector(`input[name='height']`);
            if(input) {
                this.inputValidation(input, locale.validationHeightRequired);
            }
        } catch (error) {
            throw error;
        }
    }


    agreementsValidation = () => {
        const { locale, agreements } = this;
        const { types } = this.notification;

        agreements.forEach(agreement => {
            if (agreement.required && !agreement.isSigned()) {
                throw new ValidationException({type: types.warning, message: locale.validationAgreementsRequired, htmlElement: agreement.htmlElement});
            } 
        });
    }

    answersValidation = () => {
        const { locale, questions } = this;
        const { types } = this.notification; 

        questions.forEach(question => {
            if (question.meetsDisplayConditions() && (question.answerRequired && !question.answerSelected())) {
                throw new ValidationException({type: types.warning, message: locale.validationQuestionsAnswerRequired, htmlElement: question.htmlElement});
            } 
        });
    }

    answerCommentsValidation = () => {
        const { locale, questions } = this;
        const { types } = this.notification; 

        questions.forEach(question => {
            if (question.meetsDisplayConditions() && (question.answerRequired && question.answerSelected())) {
                if(question.isCommentEnabled() && question.getComment() === null) {
                    throw new ValidationException({type: types.warning, message: locale.validationQuestionsCommentRequired, htmlElement: question.htmlElement});
                }
            } 
        });
    }
}