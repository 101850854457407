export default class Question {

    constructor(htmlElement) {
        this.htmlElement = htmlElement;

        this.id = htmlElement.getAttribute('data-id');
        this.type = htmlElement.getAttribute('data-type');
        this.displayCondition = htmlElement.getAttribute('data-display-condition');
        this.answerRequired = String(htmlElement.getAttribute('data-answer-required')) === 'true';
        this.hasComment = String(htmlElement.getAttribute('data-has-comment')) === 'true';
        this.commentRequiredIfAnswered = htmlElement.getAttribute('data-comment-required-if-answered').split(",");

        this.initQuestion();

        this.bindDisplayConditions();
        this.bindComment();
    }

    initQuestion = () => {
        const { hasComment } = this;

        this.evaluateDisplayCondition();
        if (hasComment) this.initComment();
    }

    bindDisplayConditions = () => {
        let genderHtmlElement = document.querySelector(`select[name='gender']`);

        genderHtmlElement.addEventListener('change', async e => {
            try {
                // e.preventDefault();
                this.evaluateDisplayCondition();
            } catch (error) {
            }
        });
    }

    evaluateDisplayCondition = () => {
        const { displayCondition } = this;

        switch (displayCondition) {
            case 'WOMAN':
                let gender = document.querySelector(`select[name='gender']`).value;

                if ('FEMALE' === gender) this.show();
                else this.hide();
                break;
            default:
                this.show();
                break;
        }
    }

    initComment = () => {
        const { commentRequiredIfAnswered } = this;
        const answer = this.getValue();

        if (commentRequiredIfAnswered.includes(answer)) this.enableComment();
        else this.disableComment();
    }

    bindComment = () => {
        const { htmlElement, hasComment } = this;
        let that = this;

        if (hasComment) {
            htmlElement.querySelectorAll(`input`).forEach((radioButton) => {
                radioButton.addEventListener(`change`, function (event) {
                    that.initComment();
                });
            });
        }
    }

    getValue = () => {
        const { htmlElement, type } = this;

        switch (type) {
            case 'SINGLE_CHOICE':
                let radioButton = htmlElement.querySelector(`input:checked`);
                if (radioButton) return radioButton.value;
                break;
            default:
                let input = htmlElement.querySelector(`input`);
                if (input) return htmlElement.querySelector(`input`).value;

                let select = htmlElement.querySelector(`select`);
                if (select) return htmlElement.querySelector(`select`).value;
                break;
        }
    }

    getAnswers = () => {
        let answers = this.getValue();

        if(this.meetsDisplayConditions()) {
            if(Array.isArray(answers)) return answers;
            else return new Array(answers);
        }

        return new Array();
    }

    getComment = () => {
        const { htmlElement } = this;
        const commentHtmlElement = htmlElement.querySelector(`textarea[name='comment']`);

        if(this.isCommentEnabled()) {
            let value = commentHtmlElement.value;
            value = value.trim();

            if(value.length) return value;
            return null;
        }

        return null;
    }

    meetsDisplayConditions = () => {
        const { htmlElement } = this;

        if(htmlElement) {
            if('block' === htmlElement.style.display) return true;
            else return false;
        }

        return false;
    }

    isCommentEnabled = () => {
        const { htmlElement } = this;
        const commentHtmlElement = htmlElement.querySelector(`textarea[name='comment']`);

        if(this.meetsDisplayConditions() && commentHtmlElement) {
            if(commentHtmlElement.disabled) return false;
            else return true;
        }

        return false;
    }

    answerSelected = () => {
        return this.getValue() ? true : false;
    }

    show = () => {
        const { htmlElement } = this;
        htmlElement.style.display = 'block';
    }

    hide = () => {
        const { htmlElement } = this;
        htmlElement.style.display = 'none';
    }

    enableComment = () => {
        const { htmlElement } = this;
        const commentHtmlElement = htmlElement.querySelector(`textarea[name='comment']`);
        commentHtmlElement.disabled = false;

        /**
         * Set as required if needed...
         */
        commentHtmlElement.setAttribute('required', 'required');
    }

    disableComment = () => {
        const { htmlElement } = this;
        const commentHtmlElement = htmlElement.querySelector(`textarea[name='comment']`);
        commentHtmlElement.value = '';
        commentHtmlElement.disabled = true;

        /**
         * Unset as required if needed...
         */
        commentHtmlElement.removeAttribute('required');
    }
}