export default class Agreement {

    constructor(htmlElement) {
        this.htmlElement = htmlElement;

        this.id = htmlElement.getAttribute('data-id');
        this.required = htmlElement.getAttribute('data-required');
    }

    isSigned = () => {
        const { htmlElement } = this;

        let checkbox = htmlElement.querySelector(`input[type='checkbox']`);
        if (checkbox) return checkbox.checked;
        else return true; // TODO: Error, checkbox input not defined;
    }
}