import 'bootstrap-select';
import 'daterangepicker';

export default class Form {

    datePickers = [];
    dateRangePickers = [];

    constructor(form) {
        this.formElement = form;
        this.inputs = this.formElement ? this.formElement.querySelectorAll('input') : [];
        this.checkboxes = this.formElement ? this.formElement.querySelectorAll('input[type=checkbox]') : [];
        this.selectPickers = this.formElement ? this.formElement.querySelectorAll('select.selectpicker') : [];
        this.datePickers = document.querySelectorAll('input[data-provide="datepicker"]');
        this.dateRangePickers = document.querySelectorAll('input[data-provide="daterangepicker"]');

        this.initDatepickers();
        this.initSelectPickers();
    }

    initDatepickers = () => {
        this.datePickers.forEach(input => {
            $(input).daterangepicker({
                singleDatePicker: true,
                applyButtonClasses: "button",
                cancelClass: "button button--alt",
                locale: {
                    format: 'YYYY-MM-DD'
                }
            });
        });

        this.dateRangePickers.forEach(input => {
            $(input).daterangepicker({
                applyButtonClasses: "button",
                cancelClass: "button button--alt",
                locale: {
                    format: 'YYYY-MM-DD'
                }
            });
        });
    }

    initSelectPickers = () => {
        $.fn.selectpicker.Constructor.DEFAULTS.liveSearch = true;
        $.fn.selectpicker.Constructor.DEFAULTS.size = 8;
    }

    clear = () => {
        const inputs = this.formElement.querySelectorAll('input');
        inputs.forEach(input => {
            input.value = "";
        });

        const selects = this.formElement.querySelectorAll('select');
        selects.forEach(select => {
            select.selectedIndex = 0;
            $(select).selectpicker('refresh');
        });
    }

    fill = config => {
        this.formElement.dataset.id = config.data.id;

        config.inputs.forEach((name) => {
            const input = this.formElement.querySelector(`input[name="${name}"]`);
            input.value = config.data[name];
        });

        config.selects.forEach((name) => {
            const input = this.formElement.querySelector(`select[name="${name}"]`);
            input.value = config.data[name];
            $(`select[name="${name}"]`).selectpicker('refresh');
        });
    }

    showLoader = () => {

    }
}